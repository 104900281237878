import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"cyrillic\"]}],\"variableName\":\"raleway\"}");
import(/* webpackMode: "eager" */ "/app/src/app/google-captcha-wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/header/header.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
import(/* webpackMode: "eager" */ "/app/src/components/layout/layout.module.scss");
import(/* webpackMode: "eager" */ "/app/src/uikit/container/container.module.scss");
import(/* webpackMode: "eager" */ "/app/src/uikit/button/button.module.scss");
import(/* webpackMode: "eager" */ "/app/src/uikit/button-base/button-base.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/footer/footer.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/social-media/social-media.module.scss");
import(/* webpackMode: "eager" */ "/app/src/uikit/button-icon/button-icon.module.scss")