import { useEffect } from 'react'

export const useBlockScrollIf = (shouldBlock: boolean): void => {
  useEffect(() => {
    const enable = () => {
      document.body.style.width = '100%'
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    }

    const disable = () => {
      document.body.style.removeProperty('width')
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('touch-action')
    }

    if (shouldBlock) {
      enable()
      return disable
    }
  }, [shouldBlock])
}
