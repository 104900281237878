import { SearchCatalog, RegionSearchPublic } from '@/api'
import { searchApi } from './search.api'
import { handleApiError } from '@/lib/api'
import { removeUndefined } from '@/lib/object'

export const searchElement = async (query: string): Promise<SearchCatalog> => {
  const response = await searchApi.searchCatalogRetrieve({ query }).catch(handleApiError)

  if (response === undefined) {
    throw `"response" is undefined for request "searchCatalogRetrieve(${query})"`
  }

  if (response.body == null) {
    throw `"response.body" is ${response.body} for request "searchCatalogRetrieve(${query})"`
  }

  return removeUndefined(response.body)
}

export const searchRegions = async (query?: string): Promise<RegionSearchPublic[]> => {
  const response = await searchApi.searchRegionList({ query }).catch(handleApiError)

  if (response === undefined) {
    throw `"response" is undefined for request "locationRegionList()"`
  }

  if (response.body == null) {
    throw `"response.body" is ${response.body} for request "locationRegionList()"`
  }

  return removeUndefined(response.body)
}
