export const ROUTES = {
  MAIN: '/',
  SERVICES: 'services',
  ABOUT: 'about',
  JOBS: 'jobs',
  CONTACTS: 'contacts',
  ORDER: 'order',
  LOCATIONS: 'locations',
  DOCUMENTS: 'documents',
  COOKIES_DOCUMENT: 'documents/soglashenie-ob-ispolzovanii-cookie',
  PRICE: 'price',
  LOCATION: 'location',
  PORTFOLIO: 'portfolio',
  CALCULATOR: 'calculator',
  CATALOG: 'catalog',
  REVIEW: 'review',
}
