import { ROUTES } from './routes'

export const MENU = [
  {
    text: 'О компании',
    link: ROUTES.ABOUT,
  },
  {
    text: 'Портфолио',
    link: ROUTES.PORTFOLIO,
  },
  {
    text: 'Калькулятор',
    link: ROUTES.CALCULATOR,
  },
  {
    text: 'Контакты',
    link: ROUTES.CONTACTS,
  },
  {
    text: 'Каталог',
    link: ROUTES.CATALOG,
  },
  {
    text: 'Отзывы',
    link: ROUTES.REVIEW,
  },
  {
    text: 'Документы',
    link: ROUTES.DOCUMENTS,
  },
]
