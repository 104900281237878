import Cookies from 'js-cookie'

let getRootDomain = (host: string) => host.split('.').slice(-2).join('.')

// SSR fallback just in the case
let domain = process.env.NODE_ENV === 'production' ? 'remaster.site' : 'localhost'

if (typeof window !== 'undefined') {
  domain = getRootDomain(location.hostname)
}

export let setCookie = (name: string, value: string, options = {}) =>
  Cookies.set(name, value, { domain, ...options })

export let getCookie = Cookies.get

export let removeCookie = (name: string, options = {}) =>
  Cookies.remove(name, { domain, ...options })
