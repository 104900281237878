import { ChangeEvent, HTMLProps, forwardRef } from 'react'

import { InputWrapper, InputWrapperProps } from '@uikit/input-wrapper'

export interface InputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'size'>,
    Omit<InputWrapperProps, 'onClick'> {
  onClick?: InputWrapperProps['onClick']
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    isError,
    variant,
    type = 'text',
    pre,
    post,
    disabled,
    onClick,
    size = 'normal',
    ...rest
  } = props

  return (
    <InputWrapper
      pre={pre}
      post={post}
      size={size}
      variant={variant}
      isError={isError}
      disabled={disabled}
      onClick={onClick}
    >
      <input
        ref={ref}
        type={type}
        disabled={disabled}
        id={rest.name}
        {...rest}
        onInput={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value.replace(/\s/g, '').length === 0) {
            e.target.value = ''
          }
        }}
      />
    </InputWrapper>
  )
})

Input.displayName = 'Input'
