'use client'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ReactNode } from 'react'

import { config } from '@/constants/config'

export default function GoogleCaptchaWrapper({ children }: { children: ReactNode }) {
  const recaptchaKey: string | undefined = config.googleCaptchaKey

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey ?? 'NOT DEFINED'}>
      {children}
    </GoogleReCaptchaProvider>
  )
}
