import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { RefObject } from 'react'

import { useIsomorphicLayoutEffect } from '@/lib/use-isomorphic-layout-effect'

export const useBodyScrollLock = <Element extends HTMLElement>(
  isLocked: boolean,
  targetElement: RefObject<Element>,
): void => {
  useIsomorphicLayoutEffect(() => {
    if (targetElement.current === null) {
      return
    }

    if (isLocked) {
      disableBodyScroll(targetElement.current)
    } else {
      enableBodyScroll(targetElement.current)
    }
  }, [isLocked, targetElement])

  useIsomorphicLayoutEffect(
    () => () => {
      if (targetElement.current === null) {
        return
      }

      enableBodyScroll(targetElement.current)
    },
    [targetElement],
  )
}
