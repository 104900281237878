'use client'

import { withStyles } from '@bruitt/classnames'
import { FC, useState, useEffect } from 'react'
import { useRouter, usePathname, redirect } from 'next/navigation'
import Image from 'next/image'
import { useForm, Controller } from 'react-hook-form'
import * as Sentry from '@sentry/nextjs'

import { StaticPageDetail, RegionSearchPublic, ApiMenu } from '@/api'
import { setCookie, getCookie } from '@/lib/cookies'
import { useBlockScrollIf } from '@/lib/use-block-scroll-if'
import { useMediaScreen } from '@/lib/use-media-screen'
import { MENU } from '@/constants/menu'
import { REGION } from '@/constants/storage-keys'
import { config } from '@/constants/config'
import { ROUTES } from '@/constants/routes'
import { Container } from '@uikit/container'
import { Button } from '@uikit/button'
import { Dialog } from '@/uikit/dialog'
import { Input } from '@/uikit/input'
import { FormField } from '@/uikit/form-field'
import { searchRegions } from '@/modules/search'
import { DesktopMenu } from './desktop-menu'

import styles from './header.module.scss'

const sx = withStyles(styles)

interface HeaderProps {
  host?: string
  regions?: RegionSearchPublic[]
  contacts?: StaticPageDetail
  menu: ApiMenu
}

const SUGGEST_DELAY = 500

const getDefaultRegion = (regions?: RegionSearchPublic[]) => {
  if (!regions) return null

  let region = regions.find(({ name }) => name === 'Москва')

  if (region) {
    return region
  }

  region = regions[0]

  return region
}

export const Header: FC<HeaderProps> = ({ regions, host, contacts, menu }) => {
  const router = useRouter()
  const pathname = usePathname()
  const mediaScreen = useMediaScreen()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentRegion, setCurrentRegion] = useState<RegionSearchPublic | null>(null)
  const [regionList, setRegionList] = useState<RegionSearchPublic[]>(
    regions?.length ? regions : [],
  )
  const siteUrl = config.siteUrl
  const phone = contacts?.content?.find(({ slug }) => slug === 'contacts')?.otherData?.phone

  const form = useForm<any>({
    reValidateMode: 'onChange',
  })

  const watchQuery = form.watch('query')

  useEffect(() => {
    const domain = host?.replace(/^https?:\/\//, '')
    // console.log(1.1, 'domain', domain)
    // const domain = 'https://orel.develop.umelodelo.ru'.replace(/^https?:\/\//, '')
    const currentSubdomain = domain?.replace(siteUrl, '')?.replace('.', '')
    // console.log(1.2, 'currentSubdomain', currentSubdomain)
    const cookieRegion = getCookie(REGION)
    // console.log(1.3, 'cookieRegion', cookieRegion)

    let newRegion = regions?.find(({ subdomain }) => {
      // console.log(1.4, 'subdomain', subdomain)
      if (!currentSubdomain) return !subdomain
      return subdomain === currentSubdomain
    })

    if (cookieRegion && newRegion) {
      const cookieRegionName = JSON.parse(cookieRegion).name

      // console.log(1.5, 'cookie', cookieRegionName)

      newRegion = {
        ...newRegion,
        name: cookieRegionName,
      }
    }

    // console.log(1.6, 'newRegion', newRegion)

    if (!newRegion) {
      const region = getDefaultRegion(regions)
      // console.log(1.7, 'getDefaultRegion', REGION, region)

      setCurrentRegion(region)
      if (!region) return

      setCookie(
        REGION,
        JSON.stringify({ id: region.id, name: region.name, subdomain: region.subdomain }),
      )

      return
    }

    setCurrentRegion(newRegion)
    setCookie(
      REGION,
      JSON.stringify({ id: newRegion.id, name: newRegion.name, subdomain: newRegion.subdomain }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useBlockScrollIf(toggleMenu)

  useEffect(() => {
    // if (!watchQuery) return

    const delayDebounceFn = setTimeout(() => {
      const query = watchQuery
      searchRegions(query)
        .then((res) => {
          setRegionList(res)
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              some: 'searchRegions',
            },
            level: 'warning',
          })
        })
    }, SUGGEST_DELAY)

    return () => clearTimeout(delayDebounceFn)
  }, [watchQuery])

  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.btn_block}>
            <div className={styles.logo}>
              <Button
                variant="neutral"
                onClick={() => {
                  location.href = '/'
                }}
                aria-label="Перейти на главную страницу"
                className={styles.logo}
              >
                <Image
                  src="/logo.svg"
                  alt="icon"
                  fill
                  object-fit="fill"
                  priority
                  sizes="(max-width: 1240px) 194px, 270px"
                />
              </Button>
            </div>
            <div className={styles.btn_location}>
              {currentRegion?.name && (
                <>
                  <div className={styles.label}>Регион:</div>
                  <Button
                    className={styles.btn_region}
                    variant="black-link"
                    onClick={() => setIsOpenModal(true)}
                  >
                    {currentRegion?.name}
                  </Button>
                </>
              )}

              <Dialog onClose={() => setIsOpenModal(false)} open={isOpenModal} variant="absolut">
                <form>
                  <FormField htmlFor="query">
                    <Controller
                      name="query"
                      control={form.control}
                      render={({ field }) => (
                        <Input placeholder="Введите регион или город" {...field} />
                      )}
                    />
                  </FormField>
                </form>
                {!!regionList?.length ? (
                  <div className={styles.regions}>
                    {regionList.map((region) => (
                      <div key={region.id}>
                        <Button
                          variant="black-link"
                          className={sx('region', { active: region.id === currentRegion?.id })}
                          onClick={() => {
                            const cookieRegion = getCookie(REGION)
                            let cookieRegionSubdomain

                            if (cookieRegion) {
                              cookieRegionSubdomain = JSON.parse(cookieRegion).subdomain
                            }

                            // console.log(1.81, 'click region', 'cookieRegionSubdomain', region)

                            setCookie(
                              REGION,
                              JSON.stringify({
                                id: region.id,
                                name: region.name,
                                subdomain: region.subdomain,
                              }),
                            )
                            setCurrentRegion(region)
                            setIsOpenModal(false)

                            // console.log(1.8, 'cookieRegionSubdomain', cookieRegionSubdomain)

                            if (cookieRegionSubdomain === region.subdomain) {
                              // console.log(1.9, 'region.subdomain', region.subdomain)
                              pathname ? redirect(pathname) : redirect('/')
                            } else {
                              // console.log(1.11)
                              region.subdomain
                                ? location.replace(
                                    `https://${region.subdomain}.${siteUrl}${pathname}`,
                                  )
                                : location.replace(`https://${siteUrl}${pathname}`)
                            }
                          }}
                        >
                          <span className={styles.region_name}>{region.name}</span>
                          {region?.province && (
                            <span className={styles.province}>{region.province}</span>
                          )}
                        </Button>
                        {region.matchedCities.map((city) => (
                          <Button
                            key={city}
                            variant="black-link"
                            className={sx('city', { active: region.id === currentRegion?.id })}
                            onClick={() => {
                              const cookieRegion = getCookie(REGION)
                              let cookieRegionSubdomain

                              if (cookieRegion) {
                                cookieRegionSubdomain = JSON.parse(cookieRegion).subdomain
                              }

                              const newRegion = {
                                ...region,
                                name: city,
                              }
                              setCookie(
                                REGION,
                                JSON.stringify({
                                  id: newRegion.id,
                                  name: newRegion.name,
                                  subdomain: newRegion.subdomain,
                                }),
                              )
                              setCurrentRegion(newRegion)
                              setIsOpenModal(false)

                              // console.log(1.8, 'cookieRegionSubdomain', cookieRegionSubdomain)

                              if (cookieRegionSubdomain === region.subdomain) {
                                // console.log(1.9, 'region.subdomain', region.subdomain)
                                pathname ? redirect(pathname) : redirect('/')
                              } else {
                                // console.log(1.11)
                                region.subdomain
                                  ? location.replace(
                                      `https://${region.subdomain}.${siteUrl}${pathname}`,
                                    )
                                  : location.replace(`https://${siteUrl}${pathname}`)
                              }
                            }}
                          >
                            <span className={styles.dot}>&#8226;</span> {city}
                          </Button>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.empty_regions}>Такого города или региона у нас нет</div>
                )}
              </Dialog>
            </div>
            <div className={styles.btn_menu}>
              <Button
                variant="black-link"
                onClick={() => {
                  if (mediaScreen === 'smedium' || mediaScreen === 'small') {
                    router.push(`/${ROUTES.CATALOG}`)

                    return
                  }

                  setToggleMenu(!toggleMenu)
                }}
                ariaLabel="Открыть меню"
              >
                <div className={styles.menu_icon_wrapper}>
                  <div className={sx('menu_icon', { active: toggleMenu })}>
                    <span />
                  </div>
                </div>
                <span className={styles.menu_text}>Каталог услуг</span>
              </Button>
              <DesktopMenu
                menu={menu}
                active={toggleMenu}
                handleOverlay={() => setToggleMenu(false)}
              />
            </div>
          </div>
          <div className={styles.menu_list}>
            {MENU.filter((item) =>
              ['Портфолио', 'Калькулятор', 'Контакты'].includes(item.text),
            ).map(({ text, link }) => (
              <Button key={text} variant="gray-link" href={`/${link}`}>
                {text}
              </Button>
            ))}
          </div>
          <div className={styles.phone}>
            <Button className={styles.phone_number} variant="black-link" href={`tel:${phone}`}>
              {phone}
            </Button>
          </div>
        </div>
      </Container>
    </header>
  )
}
