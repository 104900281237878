import { withStyles } from '@bruitt/classnames'
import { ComponentProps, ReactElement, forwardRef } from 'react'

import { ButtonBase } from '@uikit/button-base'

import styles from './button-icon.module.scss'

type ButtonIconProps = ComponentProps<typeof ButtonBase> & {
  children: ReactElement
  ariaLabel?: string
}

const sx = withStyles(styles)

export const ButtonIcon = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonIconProps>(
  ({ children, className, ariaLabel, ...rest }, ref) => (
    <ButtonBase ref={ref} aria-label={ariaLabel} className={sx(styles.root, className)} {...rest}>
      {children}
    </ButtonBase>
  ),
)

ButtonIcon.displayName = 'ButtonIcon'
