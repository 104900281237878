'use client'

import { withStyles } from '@bruitt/classnames'
import {
  Children,
  FocusEvent,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
} from 'react'

import styles from './input-wrapper.module.scss'

const sx = withStyles(styles)

export interface InputWrapperProps {
  isError?: boolean
  variant?: 'stroked'
  size?: 'normal'
  pre?: ReactNode
  post?: ReactNode
  disabled?: boolean
  className?: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  children?: ReactNode
}

export const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>((props, ref) => {
  const {
    isError = false,
    variant = 'stroked',
    size = 'normal',
    disabled,
    pre,
    post,
    children,
    className,
    onClick,
  } = props
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (inputRef?.current) {
      inputRef.current?.focus?.()

      onClick?.(event)
    }
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement

    if (!['INPUT', 'SELECT', 'TEXTAREA'].includes(target.tagName)) {
      event.preventDefault()
    }
  }

  return (
    <div
      className={sx('root', className, {
        variant,
        size,
        isError,
        disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      {pre && <span className={styles.enhancer}>{pre}</span>}

      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child as ReactElement, {
            className: sx(styles.input, child?.props?.className, {
              isPre: Boolean(pre),
              isPost: Boolean(post),
            }),
            onBlur: (
              event: FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
            ) => {
              child.props?.onBlur?.(event)
            },
            onFocus: (
              event: FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
            ) => {
              child.props?.onFocus?.(event)
            },
            /**
             * getInputRef is used for react-number-format
             * as input-wrapper's child, because it can't accept basic ref.
             */
            // ...(['NumericFormat', 'PatternFormat'].includes(child.type?.name)
            //   ? {
            //       getInputRef: (input: HTMLInputElement) => {
            //         inputRef.current = input
            //         if (ref) {
            //           ;(ref as MutableRefObject<HTMLInputElement>).current = input
            //         }
            //       },
            //     }
            //   : {
            //       ref: (node: never) => {
            //         inputRef.current = node
            //         // @ts-expect-error how to set types for ref?
            //         if (typeof child?.ref === 'function') child.ref?.(node)
            //         // @ts-expect-error how to set types for ref?
            //         else if (child.ref) child.ref.current = node
            //       },
            //     }),
          }),
      )}

      {post && <span className={styles.enhancer}>{post}</span>}
    </div>
  )
})

InputWrapper.displayName = 'InputWrapper'
