import { withStyles } from '@bruitt/classnames'
import { FC, useState, useEffect } from 'react'

import { ApiMenu } from '@/api'
import { ROUTES } from '@/constants/routes'
import { Button } from '@/uikit/button'
import { SvgNextArrow } from '@/uikit/svg'

import styles from './desktop-menu.module.scss'

interface DesktopMenuProps {
  active: boolean
  handleOverlay: () => void
  menu: ApiMenu
}

const sx = withStyles(styles)

export const DesktopMenu: FC<DesktopMenuProps> = ({ active = false, handleOverlay, menu }) => {
  const [activeFisrtLink, setActiveFirstLink] = useState<number | undefined>()
  const [activeSecondLink, setActiveSecondLink] = useState<number | undefined>()

  useEffect(() => {
    if (!active) {
      setActiveFirstLink(undefined)
      setActiveSecondLink(undefined)
    }
  }, [active])

  return (
    <div className={sx('menu', { active })}>
      <div className={styles.overlay} onClick={handleOverlay} />
      <div className={styles.wrapper}>
        <div className={styles.first_level}>
          {!!menu.menu.length &&
            menu.menu
              .filter(({ isEnabled }) => isEnabled)
              .map((item) => (
                <div
                  key={item.key}
                  className={sx('btn_menu', { active_link: item.key === activeFisrtLink })}
                >
                  <Button
                    variant="gray-link"
                    href={`/${ROUTES.SERVICES}/${item.slug}`}
                    onClick={() => handleOverlay()}
                    className={styles.btn_link}
                    onMouseEnter={() => {
                      setActiveFirstLink(item.key)
                      setActiveSecondLink(undefined)
                    }}
                  >
                    <div className={styles.btn_with_icon}>
                      {/* <Image src={item.icon} alt={item.name} width="24" height="24" /> */}
                      {item.title}
                    </div>

                    {!!item?.children?.filter(({ isEnabled }) => isEnabled)?.length && (
                      <SvgNextArrow />
                    )}
                  </Button>
                  <div className={styles.second_level}>
                    {item?.children
                      ?.filter(({ isEnabled }) => isEnabled)
                      .map((child) => (
                        <div
                          key={child.key}
                          className={sx('btn_menu', {
                            active_link: child.key === activeSecondLink,
                          })}
                        >
                          <Button
                            variant="gray-link"
                            className={styles.btn_link}
                            onMouseEnter={() => {
                              setActiveSecondLink(child.key)
                            }}
                            href={`/${ROUTES.SERVICES}/${child.slug}`}
                            onClick={() => handleOverlay()}
                          >
                            {child.title}
                            {child.children?.filter(({ isEnabled }) => isEnabled)?.length ? (
                              <SvgNextArrow />
                            ) : null}
                          </Button>
                          {!!child.children?.length && (
                            <div className={styles.third_level}>
                              {child.children
                                .filter(({ isEnabled }) => isEnabled)
                                .map(({ key, slug, title }) => (
                                  <Button
                                    key={key}
                                    variant="gray-link"
                                    className={styles.btn_menu}
                                    href={`/${ROUTES.SERVICES}/${slug}`}
                                    onClick={() => handleOverlay()}
                                  >
                                    <div className={styles.btn_link}>{title}</div>
                                  </Button>
                                ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}
