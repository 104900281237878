import { withStyles } from '@bruitt/classnames'
import { FC, HTMLProps, ReactNode } from 'react'

import styles from './form-field.module.scss'

const sx = withStyles(styles)

interface FormFieldProps {
  label?: ReactNode
  error?: any
  children?: ReactNode
  htmlFor?: HTMLProps<HTMLLabelElement>['htmlFor']
  variant?: 'white' | 'black'
}

export const FormField: FC<FormFieldProps> = ({
  label,
  error,
  children,
  htmlFor,
  variant = 'black',
}) => (
  <div className={styles.root}>
    <label
      htmlFor={htmlFor}
      className={sx({
        variant,
        label: Boolean(label),
        labelText: typeof label === 'string',
      })}
    >
      {label}
    </label>
    {children}
    <div className={styles.errorText}>{error || '\u00A0'}</div>
  </div>
)
