import { withStyles } from '@bruitt/classnames'
import { useCallback, useEffect, useRef, FC, ReactNode } from 'react'

import { useBodyScrollLock } from '@/lib/use-body-scroll-lock'
import { SvgClose } from '@/uikit/svg'
import { ButtonIcon } from '@/uikit/button-icon'

import styles from './dialog.module.scss'

interface DialogProps {
  open: boolean
  locked?: boolean
  onClose: () => void
  children: ReactNode
  title?: string
  subtitle?: string
  variant?: 'fixed' | 'absolut' | 'center'
}

const sx = withStyles(styles)

export const Dialog: FC<DialogProps> = ({
  open = false,
  locked = false,
  onClose,
  children,
  title,
  subtitle,
  variant = 'fixed',
}) => {
  const modalRef = useRef(null)

  const onCancel = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault()
      if (!locked) onClose()
    },
    [locked, onClose],
  )

  const onClick = useCallback(
    // @ts-ignore
    ({ target }) => {
      const { current } = modalRef
      if (target === current && !locked) onClose()
    },
    [locked, onClose],
  )

  const onAnimEnd = useCallback(() => {
    const { current } = modalRef
    // @ts-ignore
    if (!open) current?.close()
  }, [open])

  useEffect(() => {
    const { current } = modalRef
    // @ts-ignore
    if (open) current?.showModal()
  }, [open])

  useBodyScrollLock(open, modalRef)

  return (
    <dialog
      ref={modalRef}
      className={sx('dialog', { variant, open, closing: !open })}
      onClose={onClose}
      onCancel={onCancel}
      onClick={onClick}
      onAnimationEnd={onAnimEnd}
    >
      <ButtonIcon className={styles.btn_close} onClick={onClose}>
        <SvgClose />
      </ButtonIcon>
      {title && <div className={styles.title}>{title}</div>}
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      <div className={styles.content_wrapper}>{children}</div>
    </dialog>
  )
}
